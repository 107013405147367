
/* Board Style - FAQ */
.board-list-faq{
    border-top:2px solid  #111;
    border-bottom:1px solid  #ececec;
}
.board-list-faq .v-expansion-panel:before{
    box-shadow: none !important;
}
.board-list-faq .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after{
    border-color: #ececec;
}
.board-list-faq .v-expansion-panel-header{
    min-height:54px;
    padding:0 24px 0 16px;
}
.board-list-faq__header{
    display:flex;
    flex-wrap:wrap;
    font-size: 1.6rem;
}
.board-list-faq__header-mark{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 54px;
    font-size: 1.4rem;
    font-weight: 700;
    margin-right: 8px;
    color:#fff;
    >span{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: var(--v-primary-base);
        border-radius: 50%;
    }
}
.board-list-faq__tit{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    width: calc(100% - 62px);
    font-size: 1.6rem;
    font-weight: 500;
    color:#222;
}
.board-list-faq .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
    color: #666;
}
.board-list-faq .v-expansion-panel-content__wrap{
    padding:0;
}
.board-list-faq__content{
    padding: 16px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    border-top: 1px solid #ececec;
}
.board-list-faq__content-mark{
    min-width: 22px;
    width: 22px;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    >span{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: #999;
        border-radius: 50%;
    }
}
.board-list-faq__txt{
    width: calc(100% - 54px);
    width: 100%;
}
.board-list-faq__txt, .board-list-faq__txt >*{
    font-size: 1.4rem;
    line-height: 1.35;
    color:#666;
}
::v-deep{
    .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
        color: #666;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .board-list-faq .v-expansion-panel-header{
        min-height:60px;
        padding:0 20px 0 0;
    }
    .board-list-faq__header-mark{
        width: 60px;
        height: 60px;
        font-size: 1.6rem;
        margin-right: 0;
        >span{
            width: 28px;
            height: 28px;
        }
    }
    .board-list-faq__tit{
        width: calc(100% - 60px);
        font-size: 1.6rem;
    }
    .board-list-faq__content{
        padding: 16px 60px;
    }
    .board-list-faq__content-mark{
        min-width: 28px;
        font-size: 1.6rem;
        width:28px;
        margin-right: 12px;
        >span{
            width: 28px;
            height: 28px;
        }
    }
    .board-list-faq__txt{
        width: calc(100% - 60px);
    }
    .board-list-faq__txt, .board-list-faq__txt >*{
        font-size: 1.6rem;
    }
}
@media (min-width:1200px){
}
